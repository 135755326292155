import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Layout from "../../components/layout/layout";
import Img from "gatsby-image";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import ALink from "../../components/a-link/a-link";
import BackgroundImage from "gatsby-background-image";

import "./brands.scss";

import CallFooter from "../../components/call-footer/call-footer";
import HeaderBig from "../../components/header-big/header-big";
import Hero from "../../components/hero/hero";
import Button from "../../components/button/button";
import StickyContainer from "../../components/sticky/sticky-container";

class BrandsPage extends React.Component {
  language = "pl";

  constructor(public params: PageParams) {
    super(params);
    this.language = params.pageContext.language;
  }

  render() {
    return (
      <>
        <SEO title="Brandy" description="" lang={this.language}/>
        <Hero>
          <div className="container-xs header-padding">
            <h1>Brandy</h1>
            <div className="header-subline">
              <h2 className="subtitle">
                Wraz ze wzrostem liczby realizowanych projektów - tworzymy
                narzędzia, które umożliwiają optymalizację procesów i&nbsp;
                zwiększenie satysfakcji klientów.
              </h2>
            </div>
            <h4>
              Chcąc zachować wysoki poziom świadczonych usług, wprowadziliśmy
              w&nbsp;firmie strukturę, która umożliwia sprawne działanie
              w&nbsp;obszarach: Tworzenia Stron intetowych opartych
              o&nbsp;intuicyjny system (Fennec CMS), programowania dedykowanych
              systemów (Cantora Software House) oraz kreacji graficznej
              (Unirama).
            </h4>
          </div>
        </Hero>
        {/* -----------------------------------------` */}

        <StaticQuery
          render={data => (
            <div className="brands-page">
              <section className="container">
                <HeaderBig
                  tag="h2"
                  span="Fennec CMS"
                  headerText="Nasz autorski system do zarządzania stroną internetową"
                />
              </section>
              <BackgroundImage
                tag="div"
                className="brand-bg fennec-color"
                fluid={data.fennec.childImageSharp.fluid}
                backgroundColor={`#fcfcfc`}
              >
                <div className="container r :c-6">
                  <div></div>
                  <div className="container">
                    <h3>
                      Administracja stroną internetową, nigdy nie była tak
                      prosta
                    </h3>
                    <p>
                      Aktualizacja treści, zmiana układu i&nbsp;publikacja,
                      <b>bez znajomości programowania</b> to niewszystkie atuty
                      systemu Fennec CMS.
                    </p>
                  </div>
                </div>
              </BackgroundImage>

              <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
                <div data-sticky className="">
                  <Img
                    fluid={data.mac.childImageSharp.fluid}
                    className="image-test"
                    alt="Bridge"
                    title="Bridge"
                  />
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      type="default"
                      to="/o-nas/"
                      language={this.context.language}
                    >
                      Dowiedz się więcej o&nbsp;Fennec CMS
                    </Button>
                  </div>
                </div>
                <div className="container">
                  <HeaderBig
                    tag="h2"
                    span="Samodzielność"
                    headerText="Możliwiść edycji bez angażowania programistów"
                  />
                  <h3>Fennec CMS</h3>
                  <p>
                    Jest system do zarządzania treścią (CMS). Czyli po prostu
                    aplikacją do zmiany zawartości Twojej strony internetowej,
                    bloga lub sklepu. Stawia olbrzymi nacisk na samodzielną
                    pracę użytkownika. Po skończonej realizacji, bez ponoszenia
                    dodatkowych koszstów, możesz aktualizować swoją witrynę
                    samodzielnie.
                  </p>
                  <p>Do jego głównych zalet należy:</p>
                  <ul>
                    <li>prosta intuicyjna obsługa,</li>
                    <li>dostępność z&nbsp;wielu urządzeń,</li>
                    <li>podgląd na żywo, wprowadzanych zmian,</li>
                    <li>mechanizm publikacji zapewniający bezpieczeństwo,</li>
                    <li>kopia bezpieczeństwa</li>
                    <li>łatwość podłączania szablonów.</li>
                  </ul>
                  <h3>Niezawodność systemu Fennec CMS</h3>
                  <p>
                    Niezawodność i&nbsp;szybkie działanie zapewnia Angular,
                    jeden z&nbsp;najpopularniejszych framework do pisania
                    dynamicznych aplikacji. Fennec CMS powstał głównie przez
                    zapotrzebowanie na prosty polski CMS (prostszy niż Wordpress
                    czy Joomla), nie wymagający specjalistycznej wiedzy, aby
                    tworzyć lub importować własne szablony.
                  </p>
                </div>
              </StickyContainer>

              {/* ----------------------------------------------------------------- */}
              <section className="container">
                <HeaderBig
                  tag="h2"
                  span="Cantora"
                  headerText="Software House"
                />
              </section>
              <BackgroundImage
                Tag="div"
                className="brand-bg cantora-color"
                fluid={data.cantora.childImageSharp.fluid}
                backgroundColor={`#fcfcfc`}
              >
                <div className="r :c-6">
                  <div className="container">
                    <h3>
                      Tworzenie dedykowanego oprogramowania dla firm
                      i&nbsp;przedsiębiorstw
                    </h3>
                    <p>
                      Stwórz swoje oprogramowanie w&nbsp;profesjonalnym software
                      housie.Otrzymaj skalowalne, nowoczesne i&nbsp;niezawodne
                      oprogramowanie tworzone w&nbsp;optymalnych warunkach.
                    </p>
                  </div>
                  <div></div>
                </div>
              </BackgroundImage>

              <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
                <div className="container experience count">
                  <div>
                    <HeaderBig
                      tag="h2"
                      span="Zespół"
                      headerText="Profesjonalni programiści"
                    />
                    <h3>Cantora</h3>
                    <p>
                      Nasza firma posiada specjalistów gotowych pomóc Twojej
                      firmie w&nbsp;kompleksowym stworzeniu dedykowanego
                      oprogramowania - zaawansowanych portali, aplikacji
                      webowych, internetowych i&nbsp;mobilnych.
                    </p>
                    <p>Głównym obszarem działań Cantory jest:</p>
                    <ul>
                      <li>
                        udostępnienie doświadczonych specjalistów gotowych
                        zrealizować projekt,
                      </li>
                      <li>terminowość i niezawodność wdrożonych produktów,</li>
                      <li>automatyczne testy oprogramowania</li>
                    </ul>
                    <h3>Dlaczego warto współpracować z&nbsp;Catorą</h3>
                    <p>
                      Posiadamy wiedzę i&nbsp;doświadczenie w&nbsp;pracy
                      z&nbsp;Node.js, Vue.js/Angular, Docker, AWS, PHP (Laravel,
                      Symfony).
                      <br />
                      Nasza stawka za godzinę pracy programisty zależy od
                      doświadczenia i&nbsp;stanowiska poszczególnych osób
                      w&nbsp;zespole przydzielonym do realizacji projektu.
                      Dlatego koszt przygotowania produktu jest atrakcyjny na
                      tle naszej konkurencji.
                    </p>
                  </div>
                </div>
                <div data-sticky className="">
                  <Img
                    fluid={data.mac.childImageSharp.fluid}
                    className="image-test"
                    alt="Bridge"
                    title="Bridge"
                  />
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      type="default"
                      to="/o-nas/"
                      language={this.language}
                    >
                      Przejdź na stronę oferty
                    </Button>
                  </div>
                </div>
              </StickyContainer>
              {/* ----------------------------------------------------------------- */}
              <section className="container">
                <HeaderBig
                  tag="h2"
                  span="Unirama"
                  headerText="Kreacja Graficzna"
                />
              </section>
              <BackgroundImage
                Tag="div"
                className="brand-bg unirama-color"
                fluid={data.unirama.childImageSharp.fluid}
                backgroundColor={`#fcfcfc`}
              >
                <div className="container r :c-6">
                  <div></div>
                  <div className="container">
                    <h3>
                      Projektanci i specjaliści od grafiki 2D, 3D, UI i UX
                    </h3>
                    <p>
                      Mamy oryginalne pomysły i doświadczenie w&nbsp;budowaniu
                      marek, (re)brandingu, projektowaniu layoutów stron
                      internetowych oraz interfejsów aplikacji.
                    </p>
                  </div>
                </div>
              </BackgroundImage>

              <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
                <div data-sticky className="">
                  <Img
                    fluid={data.mac.childImageSharp.fluid}
                    className="image-test"
                    alt="Bridge"
                    title="Bridge"
                  />
                  <div style={{ textAlign: "center" }}>
                    <Button
                      color="primary"
                      type="default"
                      to="/o-nas/"
                      language={this.context.language}
                    >
                      Dowiedz się więcej o&nbsp;Unirama Design
                    </Button>
                  </div>
                </div>
                <div className="container">
                  <HeaderBig
                    tag="h2"
                    span="Kreatywność"
                    headerText="Tworzymy oryginalny design"
                  />
                  <h3>Unirama</h3>
                  <p>
                    Do kreatywnej pracy podchodzimy z&nbsp;pasją, opiekujemy się
                    naszymi projektami do&nbsp;ostatniej realizacji. 
                  </p>
                  <p>Do jego głównych zalet należy:</p>
                  <ul>
                    <li>prosta intuicyjna obsługa,</li>
                  </ul>
                  <h3>Oryginalność projektów </h3>
                  <p>
                    Niezawodność i&nbsp;szybkie działanie zapewnia AngularJS,
                    jeden z&nbsp;najpopularniejszych framework do pisania
                    dynamicznych aplikacji. Fennec CMS powstał głównie przez
                    zapotrzebowanie na prosty polski CMS (prostszy niż Wordpress
                    czy Joomla), nie wymagający specjalistycznej wiedzy, aby
                    tworzyć lub importować własne szablony.
                  </p>
                </div>
              </StickyContainer>

              <div className="container"></div>
            </div>
          )}
          query={graphql`
            {
              fennec: file(relativePath: { eq: "brands/fennec.jpg" }) {
                id
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              cantora: file(relativePath: { eq: "brands/cantora.jpg" }) {
                id
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              unirama: file(relativePath: { eq: "brands/unirama.jpg" }) {
                id
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              mac: file(relativePath: { eq: "mac-mock.png" }) {
                id
                childImageSharp {
                  # Specify the image processing specifications right in the query.
                  # Makes it trivial to update as your page's design changes.
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          `}
        />
        {/* -----------------------------------------` */}
        <CallFooter />
      </>
    );
  }
}

export default BrandsPage;
